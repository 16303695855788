import React, { useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";
import { HomeContext } from "../../App";

const HowItWorksSection = () => {
  const data = useContext(HomeContext);
  const howSectionContent = data?.customization?.howSection?.content;
  const howSectionSty = data?.customization?.howSection?.style;
  const howItSectionImag = data.section2_img;

  var splitag = howItSectionImag.split(",");
  var howitImg1 = splitag[0];
  var howitImg2 = splitag[1];
  var howitImg3 = splitag[2];
  var hiw_img1 = "how-it-works1.svg";
  var hiw_img2 = "how-it-works2.svg";
  var hiw_img3 = "how-it-works3.svg";
  return (
    <>
      <div className="hiw_wrapper">
        <div className="container">
          <div className="hiw_head">
            <h2 style={{ color: howSectionSty?.headingTextColor }}>
              {howSectionContent?.headingText}
            </h2>
            <p style={{ color: howSectionSty?.subheadingTextColor }}>
              {howSectionContent?.subheadingText}
            </p>
          </div>
          <div className="hiw_div">
            <div className="hiw_div_item">
              <div className="row align-items-lg-center">
                <div className="col-lg-6">
                  <div className="hiw_image">
                    <img
                      src={`static/assets/images/${
                        howitImg1 ? howitImg1 : hiw_img1
                      }`}
                      alt="howitimg"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw_content">
                    <h4 style={{ color: howSectionSty?.imageheadingColor }}>
                      {howSectionContent?.imageheading1}
                    </h4>
                    <p style={{ color: howSectionSty?.imageDescriptionColor }}>
                      {howSectionContent?.imageDescription1}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="hiw_div_item">
              <div className="row hiw_div_item_row_2 align-items-lg-center">
                <div className="col-lg-6">
                  <div className="hiw_content">
                    <h4 style={{ color: howSectionSty?.imageheadingColor }}>
                      {howSectionContent?.imageheading2}
                    </h4>
                    <p style={{ color: howSectionSty?.imageDescriptionColor }}>
                      {howSectionContent?.imageDescription2}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw_image">
                    <img
                      src={`static/assets/images/${
                        howitImg2 ? howitImg2 : hiw_img2
                      }`}
                      alt="howitimg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="hiw_div_item">
              <div className="row align-items-lg-center">
                <div className="col-lg-6">
                  <div className="hiw_image">
                    <img
                      src={`static/assets/images/${
                        howitImg3 ? howitImg3 : hiw_img3
                      }`}
                      alt="howitimg"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw_content">
                    <h4 style={{ color: howSectionSty?.imageheadingColor }}>
                      {howSectionContent?.imageheading3}
                    </h4>
                    <p style={{ color: howSectionSty?.imageDescriptionColor }}>
                      {howSectionContent?.imageDescription3}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hiw_cta_div
          "
          >
            <a className="get_startrd" href="#">
              Get Started <Icon path={mdiArrowRight} size={1} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksSection;
