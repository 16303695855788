import React, { useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiCheckAll } from "@mdi/js";
import { mdiArrowRight } from "@mdi/js";
import { HomeContext } from "../../App";

const WhySection = () => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const whySectionContent = data?.customization?.whySection?.content;
  const whySectionSty = data?.customization?.whySection?.style;
  const whyImg = data.section1_img;
  const whyImgDefault = "why-img.png";

  const whyBgStyle = {
    background: `linear-gradient(107deg, ${whySectionSty?.gradColor1} 0%, ${whySectionSty?.gradColor2} 100%)`,
  };

  const ctaStyle = {
    backgroundColor: isHover ? whySectionSty?.ctaHoverBg : whySectionSty?.ctaBg,
    color: isHover
      ? whySectionSty?.ctaHoverTextColor
      : whySectionSty?.ctaTextColor,
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <>
      <div className="whysection_wrapper" style={whyBgStyle}>
        <div className="container">
          <div
            className={`row align-items-lg-center whysection_container ${
              whySectionContent?.whySectionFlip === "Yes"
                ? "flex-row-reverse"
                : ""
            }`}
          >
            <div className="col-lg-6">
              <div className="whysection_content">
                <h2 style={{ color: whySectionSty?.headingColor }}>
                  {whySectionContent?.headingText}
                </h2>
                <p>{whySectionContent?.subheadingText}</p>
                <ul>
                  {whySectionContent?.whyPointsText1 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText1}
                    </li>
                  ) : (
                    ""
                  )}
                  {whySectionContent?.whyPointsText2 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText2}
                    </li>
                  ) : (
                    ""
                  )}
                  {whySectionContent?.whyPointsText3 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText3}
                    </li>
                  ) : (
                    ""
                  )}
                  {whySectionContent?.whyPointsText4 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText4}
                    </li>
                  ) : (
                    ""
                  )}
                  {whySectionContent?.whyPointsText5 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText5}
                    </li>
                  ) : (
                    ""
                  )}
                  {whySectionContent?.whyPointsText6 ? (
                    <li>
                      <Icon
                        style={{ color: whySectionSty?.iconColor }}
                        path={mdiCheckAll}
                        size={1}
                      />
                      {whySectionContent?.whyPointsText6}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="whysection_image">
                <img
                  src={`./static/assets/images/${
                    whyImg ? whyImg : whyImgDefault
                  }`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="whysection_cta_div
          "
          >
            <a
              className="get_startrd"
              href="#"
              style={ctaStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Get Started <Icon path={mdiArrowRight} size={1} />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.799"
                height="11.653"
                viewBox="0 0 25.799 11.653"
              >
                {" "}
                <path
                  fill="none"
                  stroke="currentColor"
                  d="M19.719 11.053a.355.355 0 00.5 0l4.977-4.975a.352.352 0 00.077-.116.355.355 0 000-.271.352.352 0 00-.077-.116L20.225.604a.355.355 0 00-.5.5l4.359 4.368H.854a.355.355 0 000 .71h23.23l-4.365 4.365a.355.355 0 000 .506z"
                ></path>{" "}
              </svg> */}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhySection;
