import React, { useContext } from "react";
import Icon from "@mdi/react";
import { HomeContext } from "../../App";
import { mdiArrowRight } from "@mdi/js";

const HomeCta = () => {
  const data = useContext(HomeContext);
  const ctaSectionContent = data?.customization?.ctaSection?.content;
  const ctaSectionsSty = data?.customization?.ctaSection?.style;
  const bgColor = {};
  return (
    <>
      <div className="home_cta_wrapper">
        <div className="container">
          <h2 style={{ color: ctaSectionsSty?.headingColor }}>
            {ctaSectionContent?.headingText}
          </h2>
          <p style={{ color: ctaSectionsSty?.subHeadingColor }}>
            {ctaSectionContent?.subheadingText}
          </p>
          <a className="get_startrd" href="#">
            {ctaSectionContent?.ctaText} <Icon path={mdiArrowRight} size={1} />
          </a>
        </div>
      </div>
    </>
  );
};

export default HomeCta;
