import React, { useState, useContext } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { Link } from "react-router-dom";
import Header from "../../section/Header";
import Footer from "../../section/Footer";
import { PageContext } from "../../App";
import PageCTA from "../../section/PageCTA";

const RateAndFees = () => {
  const pageData = useContext(PageContext);
  const ratesData = pageData.rates
    ? JSON.parse(pageData.rates)
    : { heading: "", content: { text: "" } };
  const { heading, content } = ratesData;
  return (
    <>
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> Terms Of Services{" "}
            </li>
          </ul>
          <h1>{heading}</h1>
          <div dangerouslySetInnerHTML={{ __html: content.text }} />
        </div>
      </div>
      <PageCTA />
      <Footer />
    </>
  );
};

export default RateAndFees;
