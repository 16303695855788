import React from "react";
import Header from "../../section/Header";
import Banner from "../../section/Banner";
import WhySection from "../../section/WhySection";
import BannerPoints from "../../section/BannerPoints";
import HowItWorksSection from "../../section/HowItWorksSection";
import EligibilitySection from "../../section/EligibilitySection";
import FaqSection from "../../section/FaqSection";
import HomeCta from "../../section/HomeCta";
import Footer from "../../section/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <BannerPoints />
      <WhySection />
      <HowItWorksSection />
      <EligibilitySection />
      <FaqSection />
      <HomeCta />
      <Footer />
    </div>
  );
};

export default Home;
