import React, { useState, useContext } from "react";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";
import { PageContext, HomeContext } from "../../App";

const PageCTA = () => {
  const [isHover, setIsHover] = useState(false);
  const pageData = useContext(PageContext);
  const policyData = pageData.policy
    ? JSON.parse(pageData.policy)
    : { heading: "", content: { text: "" } };
  const { heading, content } = policyData;
  const data = useContext(HomeContext);
  const ctaSectionContent = data.customization.ctaSection.content;
  const ctaSectionsSty = data.customization.ctaSection.style;
  const ctaStyle = {
    backgroundColor: isHover ? ctaSectionsSty.ctaHoverBg : ctaSectionsSty.ctaBg,
    color: isHover
      ? ctaSectionsSty.ctaHoverTextColor
      : ctaSectionsSty.ctaTextColor,
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <>
      <div className="home_cta_wrapper">
        <div className="container">
          <h2 style={{ color: ctaSectionsSty.headingColor }}>
            {ctaSectionContent.headingText}
          </h2>
          <p style={{ color: ctaSectionsSty.subHeadingColor }}>
            {ctaSectionContent.subheadingText}
          </p>
          <a
            className="get_startrd"
            style={ctaStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            href="/"
          >
            {ctaSectionContent.ctaText} <Icon path={mdiArrowRight} size={1} />
          </a>
        </div>
      </div>
    </>
  );
};

export default PageCTA;
