import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../App";
import { Link } from "react-router-dom";

const Footer = () => {
  const data = useContext(HomeContext);

  const footerSectionContent = data.customization.footer.content;
  const footerSectionSty = data.customization.footer.style;

  const logo = footerSectionContent.footerLogo;
  const logoFDef = "logo-f.svg";

  function footerStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .top_footer ul li a{
      color: ${footerSectionSty.linkColor};
    }
    .top_footer ul li a:hover{
      color: ${footerSectionSty.linkColorHover};
    }
    `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    footerStyle();
  }, [footerSectionSty]);

  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }
  return (
    <>
      <footer
        className="page_footer"
        style={{ background: footerSectionSty.backgroundColor }}
      >
        <div className="container">
          <div className="top_footer">
            <img
              src={`static/assets/images/${logo ? logo : logoFDef}`}
              alt=""
            />
            <p style={{ color: footerSectionSty.footerParaColor }}>
              {footerSectionContent.logoParaText}
            </p>
            <div className="footer_img_wrapper">
              <img src="./static/assets/images/ssl_cer.svg" alt="" />
              <img src="./static/assets/images/ssl_sec.svg" alt="" />
              <a
                href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                rel="noreferrer"
                target="_blank"
              >
                {olaimg === "" ? (
                  <div>
                    {/* <img
                    src={`static/assets/images/${footerSectionContent.encrypt}`}
                    alt=""
                    className="incrypt"
                  /> */}
                  </div>
                ) : (
                  <img
                    src={olaimg}
                    alt=""
                    className="ola_logo"
                    width="40px"
                    height="40px"
                  />
                )}
              </a>
            </div>
            <ul>
              <li>
                <Link to="/how-it-works">How It Works</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/terms-of-services">Terms of Services</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/e-consent">E-consent</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
              <li>
                <Link to="/do-not-sell">Do not sell my information</Link>
              </li>
              <li>
                <Link to="/unsubscribe">Unsubscribe</Link>
              </li>
            </ul>
          </div>
          <div className="bottom_footer">
            <p>{footerSectionContent.bottomParaText}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
