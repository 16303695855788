import React, { useContext, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiCheckAll } from "@mdi/js";
import BannerForm from "../bannerForm";
import { HomeContext } from "../../App";

const Banner = () => {
  const data = useContext(HomeContext);

  const bannerSectionContent = data?.customization.bannerSection.content;
  const bannerSectionSty = data?.customization.bannerSection.style;

  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }

  const bgColor = {
    background: bannerSectionSty.backgroundColor
      ? bannerSectionSty.backgroundColor
      : "",
  };
  return (
    <>
      <div className="banner_wrapper" style={bgColor}>
        <div className="container">
          <h5>
            <Icon
              style={{ color: bannerSectionSty.bannerIconColor }}
              path={mdiCheckAll}
              size={1}
            />
            {bannerSectionContent.bannerTopContent}
          </h5>
          <h1
            style={{ color: bannerSectionSty.HeadingColor }}
            dangerouslySetInnerHTML={{
              __html: bannerSectionContent.headingText,
            }}
          />
          <h6 style={{ color: bannerSectionSty.subHeadingColor }}>
            {bannerSectionContent.subheadingText}
          </h6>
          <BannerForm />

          {olaimg === "" ? (
            <div>
              {/* <a
                href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={`static/assets/images/${bannerSectionContent.encrypt}`}
                  alt=""
                  width="50px"
                  height="50px"
                  className="incrypt"
                />
              </a> */}
            </div>
          ) : (
            <a
              href="https://onlinelendersalliance.org/look-for-the-ola-seal"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={olaimg}
                alt=""
                className="ola_logo"
                width="50px"
                height="50px"
              />
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
