import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useQuery from "../../utils/useQuery";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import Icon from "@mdi/react";
import { mdiArrowTopRight } from "@mdi/js";
import { Link } from "react-router-dom";
import { HomeContext } from "../../App";

const BannerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isHover, setIsHover] = useState(false);
  const [focusedField, setFocusedField] = useState(false);
  const [fieldIsHover, setFieldIsHover] = useState(false);

  const data = useContext(HomeContext);

  const bannerSectionContent = data?.customization.bannerSection.content;
  const bannerSectionSty = data?.customization.bannerSection.style;

  const inputInitial = {
    border: "2px solid",
    borderColor: errors?.amount
      ? bannerSectionSty?.fieldErrorBorder
      : bannerSectionSty?.fieldBorder,
  };
  const inputFocus = {
    border: "2px solid",
    borderColor: errors?.amount
      ? bannerSectionSty?.fieldErrorBorder
      : fieldIsHover || focusedField
      ? bannerSectionSty?.fieldHoverBorder
      : bannerSectionSty?.fieldBorderColor,
  };
  const ctaparaColor = {
    color: bannerSectionSty?.termsTextColor,
  };
  const disc_link_color = {
    color: bannerSectionSty?.discLinkColor,
  };
  const ErrorMessageColor = {
    color: bannerSectionSty?.errorMessageColor,
  };

  const ctaStyle = {
    backgroundColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    color: isHover
      ? bannerSectionSty.ctaHoverTextColor
      : bannerSectionSty.ctaTextColor,
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleFieldMouseEnter = (field) => {
    //alert("Test");
    setFieldIsHover(field);
  };
  const handleFieldMouseLeave = (field) => {
    setFieldIsHover(null);
    // setFocusedField(false);
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };
  const handleBlur = () => {
    setFocusedField(false);
  };

  const maxAmount = data.max_amount;
  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const max_amount = query.get("max_amount") || "";
  const email = query.get("email") || "";

  const onSubmit = (data) => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      max_amount,
    };

    const params = {
      email: myData.email || "",
      firstName: myData.firstName || "",
      lastName: myData.lastName || "",
      s1: myData.utm_campaign || "",
      s2: myData.s2 || "",
      s3: myData.s3 || "",
      uid: myData.uid || "",
      utm_medium: myData.utm_medium || "",
      amount: myData.amount || "",
      max_amount: myData.max_amount || "",
    };

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });

    // GaTrack("First Form Submit", "First Form Submit");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="bannerForm">
        <div className="form_fields">
          <label className="pure-material-textfield-filled">
            <input
              name="amount"
              className="amount_but inline-edit"
              placeholder="$1000"
              type="tel"
              id="amount"
              onFocus={() => handleFocus("amount")}
              style={
                focusedField === "amount"
                  ? inputFocus
                  : fieldIsHover === "amount"
                  ? inputFocus
                  : inputInitial
              }
              onMouseEnter={() => handleFieldMouseEnter("amount")}
              onMouseLeave={handleFieldMouseLeave}
              {...register("amount", {
                required: "This input is required.",
                pattern: {
                  value: /\b(0|[1-9]\d*)?([05]0)\b/,
                  message: "This value should be a multiple of 50.",
                },
                min: {
                  value: 100,
                  message: `This value should be between 100 and ${maxAmount}.`,
                },
                max: {
                  value: maxAmount,
                  message: `This value should be between 100 and ${maxAmount}.`,
                },
              })}
              onBlur={handleBlur}
            />
            <span
              style={{
                color: focusedField ? bannerSectionSty.ctaBg : "",
              }}
            >
              Enter Amount You Need
            </span>
            <ErrorMessage
              errors={errors}
              name="amount"
              render={({ message }) => (
                <p style={ErrorMessageColor} className="error_color">
                  {message}
                </p>
              )}
            />
            {bannerSectionContent.AmountHint ? (
              <p
                style={{ display: !errors.amount ? "block" : "none" }}
                className="amount_hint"
              >{`${bannerSectionContent.AmountHint} $${maxAmount}.`}</p>
            ) : (
              ""
            )}
          </label>
          <button
            type="submit"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={ctaStyle}
            className="form_submit"
          >
            Get Started
            <Icon path={mdiArrowTopRight} size={1} />
          </button>
        </div>
        <p className="form_concent" style={ctaparaColor}>
          By continuing, you agree to our &nbsp;{" "}
          <Link to="/privacy-policy" target="_blank">
            <span style={disc_link_color}> Privacy Policy</span>
          </Link>
          ,&nbsp;
          <Link to="/terms-of-services" target="_blank">
            <span style={disc_link_color}>Terms</span>
          </Link>
          , &nbsp;
          <Link to="/e-consent" target="_blank">
            <span style={disc_link_color}>E-Consent</span>
          </Link>
          ,&nbsp;
          <Link to="/rate-and-fees" target="_blank">
            <span style={disc_link_color}>Rate & Fees</span>
          </Link>{" "}
          and receive special offers from us and our marketing partners via
          email communication.
        </p>
      </form>
    </>
  );
};

export default BannerForm;
