import React, { useState, useContext } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { Link } from "react-router-dom";
import Header from "../../section/Header";
import Footer from "../../section/Footer";
import { PageContext } from "../../App";
import PageCTA from "../../section/PageCTA";
import { Accordion } from "react-bootstrap";
import { HomeContext } from "../../App";
import { mdiArrowRight } from "@mdi/js";

const FaqPage = () => {
  const pageData = useContext(PageContext);
  const faqData = pageData?.faq
    ? JSON.parse(pageData?.faq)
    : { heading: "", content: { text: "" } };
  const { heading, content } = faqData;

  return (
    <>
      {" "}
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> Terms Of Services{" "}
            </li>
          </ul>

          <div className="row align-items-lg-center">
            <div className="col-lg-12">
              <div className="faq_content">
                <h3>{heading}</h3>

                <div className="faq_div">
                  <Accordion defaultActiveKey="0">
                    {content?.faq?.map((item, index) => (
                      <Accordion.Item eventKey={item.id}>
                        <Accordion.Header> {item.qus}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="ans_txt"
                            dangerouslySetInnerHTML={{ __html: item.ans }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
                  <div className="faq_image">
                    <img src="./static/assets/images/faq_img.svg" alt="" />
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <PageCTA />
      <Footer />
    </>
  );
};

export default FaqPage;
