import React, { useContext } from "react";
import Icon from "@mdi/react";
import { mdiCheckAll } from "@mdi/js";
import { HomeContext } from "../../App";

const BannerPoints = () => {
  const data = useContext(HomeContext);
  const bannerPointsSectionContent =
    data?.customization?.bannerPointsSection?.content;
  const bannerPointsSectionSty =
    data?.customization?.bannerPointsSection?.style;
  return (
    <>
      <div className="bannerpoints_wrapper">
        <div className="container">
          <div className="bannerpoints_container">
            <div className="bannerpoints_div">
              <Icon
                style={{ color: bannerPointsSectionSty?.pointIconColor }}
                path={mdiCheckAll}
                size={1}
              />
              <p style={{ color: bannerPointsSectionSty?.pointTextColor }}>
                {bannerPointsSectionContent?.pointText1}
              </p>
            </div>
            <div className="bannerpoints_div">
              <Icon
                style={{ color: bannerPointsSectionSty?.pointIconColor }}
                path={mdiCheckAll}
                size={1}
              />
              <p style={{ color: bannerPointsSectionSty?.pointTextColor }}>
                {bannerPointsSectionContent?.pointText2}
              </p>
            </div>
            <div className="bannerpoints_div">
              <Icon
                style={{ color: bannerPointsSectionSty?.pointIconColor }}
                path={mdiCheckAll}
                size={1}
              />
              <p style={{ color: bannerPointsSectionSty?.pointTextColor }}>
                {bannerPointsSectionContent?.pointText3}
              </p>
            </div>
            <div className="bannerpoints_div">
              <Icon
                style={{ color: bannerPointsSectionSty?.pointIconColor }}
                path={mdiCheckAll}
                size={1}
              />
              <p style={{ color: bannerPointsSectionSty?.pointTextColor }}>
                {bannerPointsSectionContent?.pointText4}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerPoints;
