import React from "react";

import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import Header from "../../section/Header";
import Footer from "../../section/Footer";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> About Us
            </li>
          </ul>
          <h1>About Us</h1>
          <p>
            Getpersonalloan is a platform that connects borrowers with reputable
            lenders and lending partners. We do not offer loans directly, but
            rather assist in finding a loan that meets your needs. By filling
            out a loan request form on our platform, borrowers will be contacted
            by one of our partners to verify their information and finalize the
            loan. However, Getpersonalloan does not make credit decisions and is
            not responsible for the terms of any loan offered by our partners.
            Our aim is to simplify the process of finding a loan and provide
            information to connect the borrower to the right lender.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
