import React, { useContext, useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";
import { HomeContext } from "../../App";

const EligibilitySection = () => {
  const data = useContext(HomeContext);
  const eligSectionContent = data?.customization?.eligSection?.content;
  const eligSectionSty = data?.customization?.eligSection?.style;
  const elig_img = data.section3_img;
  const elig_img_def = "elig_img.svg";

  function applyBeforeTagColor() {
    const style = document.createElement("style");
    style.textContent = `
     .eligsec_wrapper{
      background:${eligSectionSty?.bgColor};
     }
     .eligsec_content h2 {
      color: ${eligSectionSty?.headingColor};
     }
     .eligsec_content ul li{
      color: ${eligSectionSty?.listTextColor};
    }
     .eligsec_content ul li::before {
        background: ${eligSectionSty?.listTextColor};
      }      
      .get_startrd{
        background: ${eligSectionSty?.ctaBg};
      }
      .get_startrd:hover{
        background: ${eligSectionSty?.ctaHoverBg};
      }
    `;
    document.head.appendChild(style);
  }
  useEffect(() => {
    applyBeforeTagColor();
  }, [eligSectionSty]);

  return (
    <>
      <div className="eligsec_wrapper">
        <div className="container">
          <div
            className={`row align-items-lg-center ${
              eligSectionContent?.eligSectionFlip === "Yes"
                ? "flex-row-reverse"
                : ""
            }`}
          >
            <div className="col-lg-6">
              <div className="eligsec_image">
                <img
                  src={`/static/assets/images/${
                    elig_img ? elig_img : elig_img_def
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="eligsec_content">
                <h2>Check eligibility criteria</h2>
                <ul>
                  {eligSectionContent?.eligPointText1 ? (
                    <li>{eligSectionContent?.eligPointText1}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText2 ? (
                    <li>{eligSectionContent?.eligPointText2}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText3 ? (
                    <li>{eligSectionContent?.eligPointText3}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText4 ? (
                    <li>{eligSectionContent?.eligPointText4}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText5 ? (
                    <li>{eligSectionContent?.eligPointText5}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText6 ? (
                    <li>{eligSectionContent?.eligPointText6}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText7 ? (
                    <li>{eligSectionContent?.eligPointText7}</li>
                  ) : (
                    ""
                  )}
                  {eligSectionContent?.eligPointText8 ? (
                    <li>{eligSectionContent?.eligPointText8}</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="eligsec_cta_div
          "
          >
            <a className="get_startrd" href="#">
              Get Started <Icon path={mdiArrowRight} size={1} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default EligibilitySection;
