import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Icon from "@mdi/react";
import { HomeContext } from "../../App";
import { mdiArrowRight } from "@mdi/js";

const FaqSection = () => {
  const data = useContext(HomeContext);

  const faqSectionContent = data.customization.faq.content;
  const faqSectionSty = data.customization.faq.style;
  const faqImg = data.section4_img;
  const faqImgDefult = "faq_img.svg";

  function FaqDynamicStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .faq_content h3{
      color: ${faqSectionSty.mainHeadingColor};
    }
    .accordion-button::after{
      color: ${faqSectionSty.themeColor};
    }
    .accordion-button, .accordion-button:not(.collapsed) {
      color: ${faqSectionSty.quesTextColor};
    }
    .accordion-body {
      color: ${faqSectionSty.paraTextColor};
    }
    .accordion-item:hover .accordion-body, .accordion-item:hover .accordion-button.collapsed {
      border-color: ${faqSectionSty.themeColor}; 
    }
    .faq_more_btn{
      color: ${faqSectionSty.CTAButtonTextColor} 
    }
    `;
    document.head.appendChild(style);
  }
  useEffect(() => {
    FaqDynamicStyle();
  }, [faqSectionSty]);
  return (
    <>
      <div className="faq_container">
        <div className="container">
          <div
            className={`row align-items-lg-center ${
              faqSectionContent?.FaqSectionFlip === "Yes"
                ? "flex-row-reverse"
                : ""
            }`}
          >
            <div className="col-lg-6">
              <div className="faq_content">
                <h3>{faqSectionContent.titleText}</h3>

                <div className="faq_div">
                  <Accordion defaultActiveKey="0">
                    {faqSectionContent.faq.map((item, index) => (
                      <Accordion.Item eventKey={item.id}>
                        <Accordion.Header> {item.qus}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="ans_txt"
                            dangerouslySetInnerHTML={{ __html: item.ans }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <Link className="faq_more_btn" to="/faq-page">
                  {" "}
                  View More
                  <Icon path={mdiArrowRight} size={1} />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq_image">
                <img
                  src={`./static/assets/images/${
                    faqImg ? faqImg : faqImgDefult
                  }`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqSection;
