import React, { useState, useContext } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import Footer from "../../section/Footer";
import PageCTA from "../../section/PageCTA";
import Header from "../../section/Header";
import UnsubscribeForm from "../../component/form/unsubscribeform";
import { Link } from "react-router-dom";
import { PageContext } from "../../App";

const Unsubscribe = () => {
  return (
    <>
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> Unsubscribe
            </li>
          </ul>
          <h1>Unsubscribe</h1>

          <h4>You Will Be Missed</h4>
          <p>
            We hate goodbyes but if you have a change of heart, we will always
            be here to search the loan option for you.
          </p>
          <UnsubscribeForm />
        </div>
      </div>
      <PageCTA />
      <Footer />
    </>
  );
};

export default Unsubscribe;
